import React from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./About.module.css";
import Heading from "./Heading";
// import Introduction from "./Introduction";
import Mission from "./Mission";
import Subheading from "./Subheading";
import Vision from "./Vision";

const About = () => {
  return (
    <>
      <Container fluid className={styles.about_container}>
        <Container fluid>
          <Heading />
        </Container>
        <Container>
          <Row>
            <Mission />
          </Row>
        </Container>
        <Container fluid>
          <Subheading />
        </Container>
        <Container>
          <Row>
            <Vision />
          </Row>
        </Container>
        {/* <Container>
          <Row>
            <Introduction />
          </Row>
        </Container> */}
      </Container>
    </>
  );
};

export default About;
