import React from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Product from "./components/products/Product";
// import Team from "./components/team/Team";
import "./App.css";

const App = () => {
  return (
    <>
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="products">
        <Product />
      </section>
      {/* <section id="foresights">
        <Foresights />
      </section>
      <section id="partners">
        <Partners />
      </section> */}
      {/* <section id="team">
        <Team />
      </section> */}
      <section id="contact">
        <Contact />
      </section>
    </>
  );
};

export default App;
