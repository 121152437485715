import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Contact.module.css";
import Socials from "./Socials";
import ContactDesign from "./ContactDesign";
import MainForm from "./MainForm";
import Footer from "../misc/Footer";
import axios from "axios";

const Contact = () => {
  const [content, setContent] = useState("");

  const getContent = async () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { data } = await axios.get(`${API_ENDPOINT}socials/content`);
    setContent(data);
  };

  useEffect(() => {
    getContent();
  }, []);

  const instagram = content[0]?.SURL;
  const facebook = content[1]?.SURL;
  const twitter = content[2]?.SURL;
  const youtube = content[3]?.SURL;
  const linkedin = content[4]?.SURL;

  return (
    <>
      <Container fluid className={styles.contact_container}>
        <Container fluid className={styles.form_container}>
          <div className="text-center">
            <h1 className={styles.contact_title}>
              <span className={styles.text_underline}>Con</span>tact
            </h1>
          </div>
          <ContactDesign />
          <Container>
            <Row>
              <Col md={6} sm={12} className={styles.display}>
                <img
                  src="./images/contact2_gif.gif"
                  alt="contact"
                  className={styles.contact2_gif}
                />
              </Col>
              <Col md={6} sm={12}>
                <MainForm />
                <div className="d-flex flex-column align-items-center">
                  <h1 className={styles.social_title}>
                    Find us on social media
                  </h1>
                  <div className="d-flex flex-row justify-content-center">
                    <a href={facebook}>
                      <Socials socials="./images/facebook.png" />
                    </a>
                    <a href={instagram}>
                      <Socials socials="./images/instagram.png" />
                    </a>
                    <a href={linkedin}>
                      <Socials socials="./images/linkedin.png" />
                    </a>
                    <a href={youtube}>
                      <Socials socials="./images/youtube.png" />
                    </a>
                    <a href={twitter}>
                      <Socials socials="./images/twitter.png" />
                    </a>
                  </div>
                  <p className={styles.social_text}>
                    ©All Right Reserved | RedPositive Service OPC Private
                    Limited
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
