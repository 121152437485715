import React from "react";
import styles from "./FooterNavbar.module.css";

const FooterNavbar = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <h2 className={styles.navbar_title}>Navigation</h2>
      <div className="d-flex flex-row justify-content-center">
        <div className={styles.nav_order}>
          <a href="#home" className={styles.navbar_text}>
            <p className={styles.navbar_subtitle}>Home</p>
          </a>
          <a href="#about" className={styles.navbar_text}>
            <p className={styles.navbar_subtitle}>About</p>
          </a>
          <a href="#products" className={styles.navbar_text}>
            <p className={styles.navbar_subtitle}>Services</p>
          </a>
          <a href="#team" className={styles.navbar_text}>
            <p className={styles.navbar_subtitle}>Team</p>
          </a>
          <a href="#contact" className={styles.navbar_text}>
            <p className={styles.navbar_subtitle}>Contact</p>
          </a>
          {/* <p className={styles.navbar_subtitle}>Foresights</p>
          <p className={styles.navbar_subtitle}>Partners</p> */}
        </div>
      </div>
    </div>
  );
};

export default FooterNavbar;
