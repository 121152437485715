import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import FooterNavbar from "./FooterNavbar";
import Logo from "./Logo";
import ContactProduct from "./ContactProduct";
import Socials from "../contact/Socials";
import axios from "axios";

const Footer = () => {
  const [content, setContent] = useState("");

  const getContent = async () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { data } = await axios.get(`${API_ENDPOINT}socials/content`);
    setContent(data);
  };

  useEffect(() => {
    getContent();
  }, []);

  const instagram = content[0]?.SURL;
  const facebook = content[1]?.SURL;
  const twitter = content[2]?.SURL;
  const youtube = content[3]?.SURL;
  const linkedin = content[4]?.SURL;

  return (
    <Container fluid className={styles.footer}>
      <Row>
        <Col md={4} sm={12} className={styles.footer_container}>
          <Logo />
          <img
            src="./images/line1_footer.png"
            alt="design"
            className={styles.line1}
          />
        </Col>
        <Col md={4} sm={12} className={styles.main_line}>
          <div className="d-flex flex-column align-items-center">
            <FooterNavbar />
            <img
              src="./images/line1_footer.png"
              alt="design"
              className={styles.line2}
            />
          </div>
        </Col>
        <Col md={4} sm={12} className="d-flex flex-column align-items-center">
          <ContactProduct />
        </Col>
      </Row>
      <Container className={styles.main_line}>
        <div className={styles.footer_order}>
          <img
            src="./images/line2_footer.png"
            alt="design"
            className={styles.line3}
          />
          <p className={styles.footer_text}>
            ©All Right Reserved | RedPositive Service OPC Private Limited
          </p>
          <img
            src="./images/line2_footer.png"
            alt="design"
            className={styles.line4}
          />
          <div className="pb-3">
            <a href={facebook}>
              <Socials socials="./images/facebook_white.png" />
            </a>
            <a href={instagram}>
              <Socials socials="./images/instagram_white.png" />
            </a>
            <a href={linkedin}>
              <Socials socials="./images/linkedin_white.png" />
            </a>
            <a href={youtube}>
              <Socials socials="./images/youtube_white.png" />
            </a>
            <a href={twitter}>
              <Socials socials="./images/twitter_white.png" />
            </a>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
