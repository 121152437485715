import React from "react";
import styles from "./Socials.module.css";

const Socials = (props) => {
  return (
    <img
      src={props.socials}
      alt="socials_logo"
      className={styles.social_logo}
    />
  );
};

export default Socials;
