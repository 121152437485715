import React from "react";
import styles from "./MainLogo.module.css";

const MainLogo = () => {
  return (
    <div className={styles.logo_container}>
      <img
        src="./images/logo_1.png"
        alt="redpositive_logo"
        className={styles.logo}
      />
      <img
        src="./images/polygon1.png"
        alt="polygon"
        className={styles.polygon1}
      />
      <img
        src="./images/mainlogoblur.png"
        alt="polygon"
        className={styles.polygon2}
      />
    </div>
  );
};

export default MainLogo;
