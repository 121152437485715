import React from "react";
import styles from "./Studio.module.css";

const Studio = () => {
  return (
    <div className={styles.studio_container}>
      <div className="d-flex flex-row justify-content-center">
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/studio1.png"
            alt="object1"
            className={styles.object1}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/studio2.png"
            alt="object2"
            className={styles.object2}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/studio3.png"
            alt="object3"
            className={styles.object3}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/studio4.png"
            alt="object4"
            className={styles.object4}
          />
        </div>
      </div>
    </div>
  );
};

export default Studio;
