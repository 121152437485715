import React, { useEffect } from "react";
import $ from "jquery";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import styles from "./MainNavbar.module.css";
import "./styles.css";

const MainNavbar = () => {
  useEffect(() => {
    const main_logo = document.querySelector(".main_logo");
    const sub_logo = document.querySelector(".sub_logo");
    $(window).on("load", function () {
      var top1 = $("#home").offset().top;
      var top2 = $("#about").offset().top;
      var top3 = $("#products").offset().top;
      var top4 = $("#team").offset().top - 10;
      var top5 = $("#contact").offset().top - 10;

      $(document).scroll(function () {
        var scrollPos = $(document).scrollTop();
        if (scrollPos >= top1 && scrollPos < top2) {
          $("nav").css("background-color", "#f40b5108");
          $(".nav_items_active").removeClass("nav_items_active");
          $(".nav_item_home").find("span").addClass("nav_items_active");
          $(".mobile_nav_border").removeClass("mobile_nav_border");
          $(".nav_item_home").addClass("mobile_nav_border");
          main_logo.classList.remove("display_container");
          sub_logo.classList.add("display_container");
        } else if (scrollPos >= top2 && scrollPos < top3) {
          $("nav").css("background-color", "#F5F5F5");
          $(".nav_items_active").removeClass("nav_items_active");
          $(".nav_item_about").find("span").addClass("nav_items_active");
          $(".mobile_nav_border").removeClass("mobile_nav_border");
          $(".nav_item_about").addClass("mobile_nav_border");
          sub_logo.classList.remove("display_container");
          main_logo.classList.add("display_container");
        } else if (scrollPos >= top3 && scrollPos < top4) {
          $("nav").css("background-color", "#f40b5108");
          $(".nav_items_active").removeClass("nav_items_active");
          $(".nav_item_services").find("span").addClass("nav_items_active");
          $(".mobile_nav_border").removeClass("mobile_nav_border");
          $(".nav_item_services").addClass("mobile_nav_border");
          sub_logo.classList.remove("display_container");
          main_logo.classList.add("display_container");
        } else if (scrollPos >= top4 && scrollPos < top5) {
          $("nav").css("background-color", "#F5F5F5");
          $(".nav_items_active").removeClass("nav_items_active");
          $(".nav_item_team").find("span").addClass("nav_items_active");
          $(".mobile_nav_border").removeClass("mobile_nav_border");
          $(".nav_item_team").addClass("mobile_nav_border");
          sub_logo.classList.remove("display_container");
          main_logo.classList.add("display_container");
        } else if (scrollPos >= top5) {
          $("nav").css("background-color", "#f40b5108");
          $(".nav_items_active").removeClass("nav_items_active");
          $(".nav_item_contact").find("span").addClass("nav_items_active");
          $(".mobile_nav_border").removeClass("mobile_nav_border");
          $(".nav_item_contact").addClass("mobile_nav_border");
          sub_logo.classList.remove("display_container");
          main_logo.classList.add("display_container");
        }
      });
    });
  });

  return (
    <Container fluid>
      <div className={styles.navbar_position}>
        {["sm"].map((expand) => (
          <Navbar key={expand} expand={expand} fixed="top">
            <Container fluid>
              <Navbar.Brand href="#home">
                <div className="main_logo">
                  <div className={styles.square1}></div>
                  <div className={styles.square2}></div>
                </div>
                <div className="sub_logo display_container">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src="./images/sub_logo.png"
                      alt="logo"
                      width={40}
                      height={40}
                    />
                    <p className={styles.navbar_heading}>Redpositive</p>
                  </div>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="ms-auto"
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                scroll={true}
                restoreFocus={false}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div className={styles.navbar_position}>
                      <img
                        src="./images/subnavbarlogo.png"
                        alt="logo"
                        className={styles.logo3}
                      />
                      <h6 className={styles.logo4}>Redpositive</h6>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1">
                    <div className="nav_item_home">
                      <Nav.Link href="#home" className={styles.nav_items}>
                        H<span className="nav_items_active">om</span>e
                      </Nav.Link>
                    </div>
                    <div className="nav_item_about">
                      <Nav.Link href="#about" className={styles.nav_items}>
                        A<span>bou</span>t
                      </Nav.Link>
                    </div>
                    <div className="nav_item_services">
                      <Nav.Link href="#products" className={styles.nav_items}>
                        Se<span>rvic</span>es
                      </Nav.Link>
                    </div>
                    <div className="nav_item_team">
                      <Nav.Link href="#team" className={styles.nav_items}>
                        T<span>ea</span>m
                      </Nav.Link>
                    </div>
                    <div className="nav_item_contact">
                      <Nav.Link href="#contact" className={styles.nav_items}>
                        Co<span>nta</span>ct
                      </Nav.Link>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </Container>
  );
};

export default MainNavbar;
