import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styles from "./Mission.module.css";

const Mission = () => {
  const [mission, setMission] = useState("");

  const getMission = async () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { data } = await axios.get(`${API_ENDPOINT}about/content`);
    setMission(data);
  };

  useEffect(() => {
    getMission();
  }, []);

  if (mission.length > 0) {
    return mission.map((data) => {
      return (
        <React.Fragment key={data.ID}>
          {data.ID === "1" && (
            <>
              <Col md={6} sm={12} className={styles.display}>
                <h1 className={styles.mobile_title}>{data.HEADING}</h1>
                <img
                  src="./images/mission_gif.gif"
                  alt="mission"
                  className={styles.mission_gif}
                />
              </Col>
              <Col md={6} sm={12} className={styles.display}>
                <div className={styles.mission_container}>
                  <h1 className={styles.main_title}>{data.HEADING}</h1>
                  <p className={styles.main_text}>{data.CONTENT}</p>
                </div>
              </Col>
            </>
          )}
        </React.Fragment>
      );
    });
  }
};
export default Mission;
