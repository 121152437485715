import React, { useEffect, useState } from "react";
import styles from "./ContactProduct.module.css";

const ContactProduct = () => {
  const [product1, setProduct1] = useState("");
  const [product2, setProduct2] = useState("");
  const [product3, setProduct3] = useState("");
  const [product4, setProduct4] = useState("");

  useEffect(() => {
    const product1 = document.querySelector("#product1");
    setProduct1(product1);
    const product2 = document.querySelector("#product2");
    setProduct2(product2);
    const product3 = document.querySelector("#product3");
    setProduct3(product3);
    const product4 = document.querySelector("#product4");
    setProduct4(product4);
  }, []);

  const product1Function = () => {
    product1.classList.remove("display_container");
    product2.classList.add("display_container");
    product3.classList.add("display_container");
    product4.classList.add("display_container");
  };

  const product2Function = () => {
    product1.classList.add("display_container");
    product2.classList.remove("display_container");
    product3.classList.add("display_container");
    product4.classList.add("display_container");
  };

  const product3Function = () => {
    product1.classList.add("display_container");
    product2.classList.add("display_container");
    product3.classList.remove("display_container");
    product4.classList.add("display_container");
  };

  const product4Function = () => {
    product1.classList.add("display_container");
    product2.classList.add("display_container");
    product3.classList.add("display_container");
    product4.classList.remove("display_container");
  };
  return (
    <div>
      <h2 className={styles.navbar_title}>Products</h2>
      <a
        href="#product1"
        className={styles.navbar_text}
        onClick={product1Function}
      >
        <p className={styles.navbar_subtitle}>Blood wala bhaiya</p>
      </a>
      <a
        href="#product2"
        className={styles.navbar_text}
        onClick={product2Function}
      >
        <p className={styles.navbar_subtitle}>The IT studio</p>
      </a>
      <a
        href="#product3"
        className={styles.navbar_text}
        onClick={product3Function}
      >
        <p className={styles.navbar_subtitle}>
          AI based defence surveillaince system
        </p>
      </a>
      <a
        href="#product4"
        className={styles.navbar_text}
        onClick={product4Function}
      >
        <p className={styles.navbar_subtitle}>
          Machine added language translator
        </p>
      </a>
    </div>
  );
};

export default ContactProduct;
