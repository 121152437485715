import React from "react";
import styles from "./LanguageTranslator.module.css";

const LanguageTranslator = () => {
  return (
    <>
      <div className="d-flex flex-row justify-content-center">
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/language1.png"
            alt="object1"
            className={styles.object1}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/language2.png"
            alt="object2"
            className={styles.object2}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/language3.png"
            alt="object3"
            className={styles.object3}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/language4.png"
            alt="object4"
            className={styles.object4}
          />
        </div>
      </div>
    </>
  );
};

export default LanguageTranslator;
