import React from "react";
import styles from "./Subheading.module.css";

const Subheading = () => {
  return (
    <div className={styles.gradient_container}>
      <h1 className={styles.gradient_text}>
        ‘When there is a vision there is a hope’
      </h1>
      <img
        src="./images/polygon2.png"
        alt="polygon"
        className={styles.polygon}
      />
    </div>
  );
};
export default Subheading;
