import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <>
      <Container fluid>
        <div className="text-center">
          <h1 className={styles.main_title}>
            <span className={styles.text_underline}>Excl</span>usive{" "}
            <span className={styles.span_title}>products</span>
          </h1>
          {/* <p className={styles.main_text}>
            Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do
            eiusmod tempor incididunt ut labore at dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod
            tempor incididunt ut labore at dolore magna aliqua. Lorem ipsum
            dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor
            incididunt ut labore at dolore magna aliqua.
          </p> */}
        </div>
      </Container>
    </>
  );
};

export default Heading;
