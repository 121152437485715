import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FaceDetectionImages from "./FaceDetectionImages";
import ObjectDetectionImages from "./ObjectDetectionImages";
import styles from "./Product1.module.css";

const Product1 = () => {
  return (
    <div className={styles.product1}>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={styles.mobile_title}>
              AI BASED DEFENCE SURVEILLANCE SYSTEM
            </h1>
            <div className={styles.display}>
              <img
                src="./images/defence_gif.gif"
                alt="defence"
                className={styles.defence_gif}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="text-center">
              <h1 className={styles.main_title}>
                AI BASED DEFENCE SURVEILLANCE SYSTEM
              </h1>
              <h6 className={styles.main_subtitle}>
                ‘Scale up security, less down the crime rate’
              </h6>
            </div>
            <p className={styles.main_text}>
              A centralised surveillance system for search/defence/offense
              operations to identify the people and objects by use of face
              detection and object detection technology.
            </p>
            <div className={styles.order_container}>
              <h3 className={styles.subheading}>Object detection</h3>
              <ObjectDetectionImages />
            </div>
            <p className={styles.main_sub_text}>
              The Object Detection module will identify entities like aircrafts,
              tanks, landmines etc. and will annotate them with relevant tags
              like "ally", "enemy", "suspicious" etc. It will also provide
              information related to the current position and expected
              trajectory of the entity from CCTVs, satellite, and live feeds
              from UAVS.
            </p>
            <div className={styles.order_container}>
              <h3 className={styles.subheading}>Face detection</h3>
              <FaceDetectionImages />
            </div>
            <p className={styles.main_sub_text}>
              The Face Recognition module will identify people and tag them as
              "ally", "enemy". "suspicious", "civilian" etc. It will also
              provide other specific details like name, age, profession etc.
              related to the person (if present in Database).
            </p>
            {/* <div className="text-center">
              <div className={styles.btn}>
                <MainButton name="Know more" />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Product1;
