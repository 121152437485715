import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Product4.module.css";
import Studio from "./Studio";

const Product4 = () => {
  return (
    <div className={styles.product4}>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={styles.mobile_title}>THE IT STUDIO</h1>
            <div className={styles.display}>
              <img
                src="./images/it_gif.gif"
                alt="IT"
                className={styles.it_gif}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="text-center">
              <h1 className={styles.main_title}>THE IT STUDIO</h1>
              <h6 className={styles.main_subtitle}>
                ‘Technology is the best when it comes to people together’
              </h6>
            </div>
            <p className={styles.main_text}>
              Projects deal with e-commerce and end-to-end delivery of services
              via user-friendly applications. Our team consists of experienced
              full-stack developers, Android developers, and iOS developers.
            </p>
            <Studio />
            {/* <p className={styles.main_sub_text}>
              Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do
              eiusmod tempor incididunt ut labore at dolore magna aliqua.Lorem
              ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod
              tempor incididunt ut labore at dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor
              incididunt ut labore at dolore magna aliqua.
            </p> */}
            {/* <div className="text-center">
              <div className={styles.btn}>
                <MainButton name="Know more" />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Product4;
