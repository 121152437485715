import React from "react";
import styles from "./ObjectDetectionImages.module.css";

const ObjectDetectionImages = () => {
  return (
    <>
      <div className="d-flex flex-row justify-content-center">
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/productrectangle.png"
            alt="product_rectangel"
            className={styles.product_rectangle}
          />
          <img
            src="./images/object1.png"
            alt="object1"
            className={styles.object}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/productrectangle.png"
            alt="product_rectangel"
            className={styles.product_rectangle}
          />
          <img
            src="./images/object2.png"
            alt="object2"
            className={styles.object}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/productrectangle.png"
            alt="product_rectangel"
            className={styles.product_rectangle}
          />
          <img
            src="./images/object3.png"
            alt="object3"
            className={styles.object}
          />
        </div>
        <div className={styles.images}>
          <img
            src="./images/productcircle.png"
            alt="product_circle"
            className={styles.product_circle}
          />
          <img
            src="./images/productrectangle.png"
            alt="product_rectangel"
            className={styles.product_rectangle}
          />
          <img
            src="./images/object4.png"
            alt="object4"
            className={styles.object_special}
          />
        </div>
      </div>
    </>
  );
};

export default ObjectDetectionImages;
