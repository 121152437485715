import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Product.module.css";
import Heading from "./Heading";
import ProductCard from "./ProductCard";
import Product1 from "./Product1";
import Product2 from "./Product2";
import Product3 from "./Product3";
import Product4 from "./Product4";
import "../../App.css";

const Product = () => {
  const [product1, setProduct1] = useState("");
  const [product2, setProduct2] = useState("");
  const [product3, setProduct3] = useState("");
  const [product4, setProduct4] = useState("");

  useEffect(() => {
    const product1 = document.querySelector("#product1");
    setProduct1(product1);
    const product2 = document.querySelector("#product2");
    setProduct2(product2);
    const product3 = document.querySelector("#product3");
    setProduct3(product3);
    const product4 = document.querySelector("#product4");
    setProduct4(product4);
  }, []);

  const product1Function = () => {
    product1.classList.remove("display_container");
    product2.classList.add("display_container");
    product3.classList.add("display_container");
    product4.classList.add("display_container");
  };

  const product2Function = () => {
    product1.classList.add("display_container");
    product2.classList.remove("display_container");
    product3.classList.add("display_container");
    product4.classList.add("display_container");
  };

  const product3Function = () => {
    product1.classList.add("display_container");
    product2.classList.add("display_container");
    product3.classList.remove("display_container");
    product4.classList.add("display_container");
  };

  const product4Function = () => {
    product1.classList.add("display_container");
    product2.classList.add("display_container");
    product3.classList.add("display_container");
    product4.classList.remove("display_container");
  };

  return (
    <>
      <Container fluid className={styles.product_container}>
        <Heading />
        <Container className={styles.card_padding}>
          <Row>
            <Col
              md={3}
              xs={6}
              className="d-flex flex-row justify-content-center"
            >
              <div onClick={product1Function}>
                <ProductCard
                  img="./images/product_3.png"
                  title="Blood wala bhaiya"
                  text="Will maintain a real time database of Blood Banks, Hospitals and Donors. Needy Recipient will be contacted and connected instantly with the potential Donor through mobile application"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex flex-row justify-content-center"
            >
              <div onClick={product2Function}>
                <ProductCard
                  img="./images/product_3.png"
                  title="The IT Studio"
                  text="Projects deal with e-commerce and end-to-end delivery of services via user-friendly applications. Our team consists of experienced full-stack developers, Android developers, and iOS developers."
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex flex-row justify-content-center"
            >
              <div onClick={product3Function}>
                <ProductCard
                  img="./images/product_1.png"
                  title="AI based defence"
                  subtitle="survellaince system"
                  text="A centralised surveillance system for search / defence / offense operations to identify the people and objects by use of face detection and object detection technology."
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex flex-row justify-content-center"
            >
              <div onClick={product4Function}>
                <ProductCard
                  img="./images/product_2.png"
                  title="Machine aided "
                  subtitle="language translator"
                  // text="Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore at dolore magna aliqua."
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <section id="product1">
        <Product3 />
      </section>
      <section id="product2" className="display_container">
        <Product4 />
      </section>
      <section id="product3" className="display_container">
        <Product1 />
      </section>
      <section id="product4" className="display_container">
        <Product2 />
      </section>
    </>
  );
};

export default Product;
