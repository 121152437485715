import React from "react";
import styles from "./FaceDetectionImages.module.css";

const FaceDetectionImages = () => {
  return (
    <>
      <>
        <div className="d-flex flex-row justify-content-center">
          <div className={styles.images}>
            <img
              src="./images/productcircle.png"
              alt="product_circle"
              className={styles.product_circle}
            />
            <img
              src="./images/productrectangle.png"
              alt="product_rectangel"
              className={styles.product_rectangle}
            />
            <img src="./images/face1.png" alt="face1" className={styles.face} />
          </div>
          <div className={styles.images}>
            <img
              src="./images/productcircle.png"
              alt="product_circle"
              className={styles.product_circle}
            />
            <img
              src="./images/productrectangle.png"
              alt="product_rectangel"
              className={styles.product_rectangle}
            />
            <img src="./images/face2.png" alt="face2" className={styles.face} />
          </div>
          <div className={styles.images}>
            <img
              src="./images/productcircle.png"
              alt="product_circle"
              className={styles.product_circle}
            />
            <img
              src="./images/productrectangle.png"
              alt="product_rectangel"
              className={styles.product_rectangle}
            />
            <img
              src="./images/face3.png"
              alt="face3"
              className={styles.face_1}
            />
          </div>
          <div className={styles.images}>
            <img
              src="./images/productcircle.png"
              alt="product_circle"
              className={styles.product_circle}
            />
            <img
              src="./images/productrectangle.png"
              alt="product_rectangel"
              className={styles.product_rectangle}
            />
            <img
              src="./images/face4.png"
              alt="face4"
              className={styles.face_special}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default FaceDetectionImages;
