import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styles from "./Vision.module.css";

const Vision = () => {
  const [vision, setVision] = useState("");

  const getVision = async () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { data } = await axios.get(`${API_ENDPOINT}about/content`);
    setVision(data);
  };

  useEffect(() => {
    getVision();
  }, []);

  if (vision.length > 0) {
    return vision.map((data) => {
      return (
        <React.Fragment key={data.ID}>
          {data.ID === "2" && (
            <>
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 6, order: 1 }}
                className={styles.display}
              >
                <div className={styles.vision_container}>
                  <h1 className={styles.main_title}>{data.HEADING}</h1>
                  <p className={styles.main_text}>{data.CONTENT}</p>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 6, order: 2 }}
                className={styles.display}
              >
                <h1 className={styles.mobile_title}>{data.HEADING}</h1>
                <img
                  src="./images/vision_gif.gif"
                  alt="vision"
                  className={styles.vision_gif}
                />
              </Col>
            </>
          )}
        </React.Fragment>
      );
    });
  }
};

export default Vision;
