import React from "react";
import ContactCard from "./ContactCard";
import styles from "./ContactDesign.module.css";

const ContactDesign = () => {
  return (
    <>
      <div className={styles.contact_container}>
        <div className={styles.display}>
          <img
            src="./images/contact1_gif.gif"
            alt="contact"
            className={styles.contact1_gif}
          />
        </div>
        <img
          src="./images/polygoncontact1.png"
          alt="contact_design"
          className={styles.contact_polygon1}
        />
        <img
          src="./images/polygoncontact2.png"
          alt="contact_design"
          className={styles.contact_polygon2}
        />
        <img
          src="./images/polygoncontact3.png"
          alt="contact_design"
          className={styles.contact_polygon3}
        />
        <div className="d-flex flex-row justify-content-center">
          <ContactCard
            img="./images/email.png"
            title="e-mail"
            details="info@redpositive.in"
            class={styles.contact_img}
          />
          {/* <ContactCard
            img="./images/call.png"
            title="Call"
            details="8005563581"
            class={styles.address_img}
          /> */}
          <ContactCard
            img="./images/address.png"
            title="Address"
            details="115 Salasar Vatika 9th, NangalnRoad, Jhotwara, Jaipur, Rajasthan 302012"
            class={styles.address_img}
          />
        </div>
      </div>
    </>
  );
};

export default ContactDesign;
