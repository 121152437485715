import React, { useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import styles from "./MainForm.module.css";
import MainButton from "../misc/MainButton";

const MainForm = () => {
  const [FNAME, setFname] = useState("");
  const [EMAIL, setEmail] = useState("");
  const [PHONE, setPhone] = useState("");
  const [MESSAGE, setMessage] = useState("");
  const [SUCCESS, setSuccess] = useState(false);

  const reset = () => {
    setFname("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const addData = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${API_ENDPOINT}contact/add`, {
        FNAME,
        EMAIL,
        PHONE,
        MESSAGE,
      });

      console.log(data);
      reset();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 4000);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div className={styles.form_container}>
      <Form onSubmit={addData}>
        <h1 className={styles.main_title}>Have some questions!!!</h1>
        <Form.Group>
          <Form.Control
            type="text"
            className={styles.form_input}
            placeholder="Full Name"
            value={FNAME}
            onChange={(e) => setFname(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="email"
            className={styles.form_input}
            placeholder="e-mail"
            value={EMAIL}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="tel"
            className={styles.form_input}
            placeholder="Phone"
            value={PHONE}
            onChange={(e) => setPhone(e.target.value)}
            pattern="[7-9]{1}[0-9]{9}"
            title="please enter a valid 10 digit phone number"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className={styles.form_textarea}
            placeholder="Message"
            value={MESSAGE}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Form.Group>
        <div className="d-flex flex-row justify-content-center">
          <div className={styles.btn}>
            <MainButton name="Submit" />
          </div>
        </div>
        {SUCCESS && (
          <h6>
            thanks for messaging us, we will shortly get back to you on your
            query !!!
          </h6>
        )}
      </Form>
    </div>
  );
};

export default MainForm;
