import React from "react";
import styles from "./Dots2.module.css";

const Dots2 = () => {
  return (
    <div className="d-flex flex-row justify-content-center">
      <div className={styles.dots_container}>
        <img src="./images/dots2.png" alt="dots2" className={styles.dots} />
        <img src="./images/arrow.png" alt="arrow" className={styles.arrow} />
        <img src="./images/arrow.png" alt="arrow" className={styles.arrow} />
        <img src="./images/arrow.png" alt="arrow" className={styles.arrow} />
        <img src="./images/dots4.png" alt="dots4" className={styles.dots} />
      </div>
    </div>
  );
};

export default Dots2;
