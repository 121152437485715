import React from "react";
import styles from "./Logo.module.css";

const Logo = () => {
  return (
    <div className={styles.footer_container}>
      <div className="d-flex flex-row justify-content-center">
        <img
          src="./images/redpositive_logo1.png"
          alt="logo"
          className={styles.footer_logo}
        />
        <div className="d-flex flex-column align-items-center mt-4">
          <h1 className={styles.main_title}>REDPOSITIVE</h1>
          <img
            src="./images/footerline1.png"
            alt="logo"
            className={styles.footer_line}
          />
          <p className={styles.main_subtitle}>SERVICE OPC PRIVATE LIMITED</p>
          {/* <p className={styles.main_text}>
            lorem ipsum dolor sit amet, consectetur adiscing elit, sed do
            eiusmod tempor incididunt ut labore at dolore magna aliqua.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Logo;
