import React from "react";
import { Card } from "react-bootstrap";
import styles from "./ContactCard.module.css";

const ContactCard = (props) => {
  return (
    <>
      <Card className={styles.card_container}>
        <Card.Body className={styles.card_body}>
          <div className="d-flex flex-row justify-content-center">
            <Card.Img
              src={props.img}
              alt="contact_logo"
              className={props.class}
            />
            <Card.Text className={styles.contact_title}>
              {props.title}
            </Card.Text>
          </div>
          <div className="text-center">
            <Card.Text className={styles.details}>{props.details}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ContactCard;
