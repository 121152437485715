import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LanguageTranslator from "./LanguageTranslator";
import styles from "./Product2.module.css";

const Product2 = () => {
  return (
    <div className={styles.product2}>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={styles.mobile_title}>
              MACHINE ADDED LANGUAGE TRANSLATOR
            </h1>
            <div className={styles.display}>
              <img
                src="./images/machine_gif.gif"
                alt="machine"
                className={styles.machine_gif}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="text-center">
              <h1 className={styles.main_title}>
                MACHINE AIDED LANGUAGE TRANSLATOR
              </h1>
              <h6 className={styles.main_subtitle}>
                ‘Create Translate Communicate’
              </h6>
            </div>
            {/* <p className={styles.main_text}>
              Lorem ipsum dolor sit amet , consectetur adiscing elit, sed do
              eiusmod tempor incididunt ut labore at dolore magna aliqua.Lorem
              ipsum dolor sit amet , consectetur adiscing elit, sed do eiusmod
              tempor incididunt ut labore at dolore magna aliqua.
            </p> */}
            <h3 className={styles.subheading}>Language translator</h3>
            <LanguageTranslator />
            {/* <p className={styles.main_text}>
              Lorem ipsum dolor sit amet , consectetur adiscing elit, sed do
              eiusmod tempor incididunt ut labore at dolore magna aliqua.Lorem
              ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod
              tempor incididunt ut labore at dolore magna aliqua.
            </p> */}
            {/* <div className="text-center">
              <div className={styles.btn}>
                <MainButton name="Know more" />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Product2;
