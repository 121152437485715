import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dots1 from "./Dots1";
import Dots2 from "./Dots2";
import styles from "./Product3.module.css";

const Product3 = () => {
  return (
    <div className={styles.product3}>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={styles.mobile_title}>BLOOD WALA BHAIYA</h1>
            <div className={styles.display}>
              <img
                src="./images/blood_gif.gif"
                alt="blood"
                className={styles.blood_gif}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="text-center">
              <h1 className={styles.main_title}>BLOOD WALA BHAIYA</h1>
              <h6 className={styles.main_subtitle}>
                ‘Helping Life, Win Battles’
              </h6>
            </div>
            <p className={styles.main_text}>
              Will maintain a real time database of Blood Banks, Hospitals and
              Donors. Needy Recipient will be contacted and connected instantly
              with the potential Donor through mobile application
            </p>
            <h3 className={styles.subheading}>
              NEED BLOOD? Go to blood wale bhaiyya app
            </h3>
            <div className="d-flex flex-row justify-content-center">
              <img
                src="./images/blood1.png"
                alt="blood"
                className={styles.blood1}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="text-center">
          <h1 className={styles.sub_title}>HOW? By connecting dots</h1>
        </div>
        <Dots1 />
        <div className="text-center">
          <h1 className={styles.sub_title}>
            MINIMIZING THE BLOOD TRANSMISSION TIME
          </h1>
        </div>
        <Dots2 />
        {/* <div className="text-center">
          <div className={styles.btn}>
            <MainButton name="Know more" />
          </div>
        </div> */}
      </Container>
    </div>
  );
};

export default Product3;
