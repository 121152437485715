import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "./Heading";
import styles from "./Home.module.css";
import MainLogo from "./MainLogo";
import MainNavbar from "../navbar/MainNavbar";

const Home = () => {
  return (
    <Container fluid className={styles.home_container}>
      <MainNavbar />
      <Row className={styles.row_container}>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 6, order: 1 }}
          className="d-flex justify-content-center"
        >
          <Heading />
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 2 }}
          className="d-flex justify-content-center"
        >
          <MainLogo />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
