import React from "react";
import { Card } from "react-bootstrap";
import styles from "./ProductCard.module.css";

const ProductCard = (props) => {
  return (
    <Card className={styles.card_container}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Card.Img
          src="./images/polygon_3.png"
          alt="design"
          className={styles.polygon}
        />
        <Card.Img
          variant="top"
          src={props.img}
          alt="product_logo"
          className={styles.card_img}
        />
        <Card.Body>
          <Card.Title className="text-center">
            <div className={styles.card_title}>{props.title}</div>
          </Card.Title>
          <Card.Title className="text-center">
            <div className={styles.card_title}>{props.subtitle}</div>
          </Card.Title>
          <Card.Text className={styles.card_text}>{props.text}</Card.Text>
        </Card.Body>
      </div>
    </Card>
  );
};

export default ProductCard;
