import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <>
      <div className={styles.heading_container}>
        <div className={styles.square1}></div>
        <div className={styles.square2}></div>
        <h2 className={styles.main_heading}>
          <span className={styles.span_about}>
            <span className={styles.text_underline}>Abo</span>ut{" "}
          </span>
          the company
        </h2>
        {/* <p className={styles.main_text}>
          lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod
          tempor incididunt ut labore at dolore magna aliqua. lorem ipsum dolor
          sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt
          ut labore at dolore magna aliqua. lorem ipsum dolor sit amet,
          consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore
          at dolore magna aliqua.
        </p> */}
      </div>
      <div className="d-flex flex-column">
        <div className={styles.gradient_container}>
          <h1 className={styles.gradient_text1}>
            ‘To satisfy the customer is the mission and
          </h1>
          <h1 className={styles.gradient_text2}>
            the purpose of every company’
          </h1>
        </div>
      </div>
    </>
  );
};

export default Heading;
